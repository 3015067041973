import React, { Suspense, lazy } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import PageLayout from "../components/layouts/page-layout";
import Loading from '../components/loader'

//----lazy load pages start-----//
export const Dashboard = lazy(() => import("../pages/dashboard"));
export const Login = lazy(() => import("../pages/login"));
export const Bookings = lazy(() => import("../pages/bookings"))
export const User = lazy(() => import("../pages/user"))
export const OnboardingRequests = lazy(() => import("../pages/onboarding-requests"))
export const Property = lazy(() => import("../pages/property"))
export const BookingDetails = lazy(() => import("../pages/booking-details"))
export const TopDestinations = lazy(() => import("../pages/top-destinations"))
export const AddTopDestination = lazy(() => import("../pages/add-top-destination"))
export const Blogs = lazy(() => import("../pages/blogs"))
export const AddBlogs = lazy(() => import("../pages/add-blogs"))
export const FeatureProperties = lazy(() => import("../pages/feature-properties"))
export const AddFeatureProperties = lazy(() => import("../pages/add-feature-property"))
export const Banners = lazy(() => import("../pages/banners"))
export const AddBanner = lazy(() => import("../pages/add-banner"))
export const Finance = lazy(() => import("../pages/finance"))
export const Analytics = lazy(() => import("../pages/analytics"))
export const PropertyDetails = lazy(() => import("../pages/property-details"))
export const RoomsDetail = lazy(() => import("../components/rooms-detail"))
export const EffortlessTravel = lazy(() => import("../pages/effortless-travel"))
export const AddEffortlessTravel = lazy(() => import("../pages/add-effortless-travel"))
export const PropertyOnboardView = lazy(() => import("../components/property-onboarding/ProperyOnboardingView"))
//----lazy load pages end-----//

export default function Router() {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const router = useRoutes([
    {
      element: (
        <PageLayout>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </PageLayout>
      ),
      children: [
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "bookings",
          element: <Bookings />,
        },
        {
          path: "bookings/details/:id",
          element: <BookingDetails />,
        },
        {
          path: "property",
          element: <Property />,
        },
        {
          path: "property/property-onboarding",
          element: <PropertyOnboardView />,
        },
        {
          path: "property/details/:id",
          element: <PropertyDetails />,
        },
        {
          path: "property/details/:id/add-rooms",
          element: <RoomsDetail />,
        },
        {
          path: "user",
          element: <User />,
        },
        {
          path: "onboarding-requests",
          element: <OnboardingRequests />,
        },
        {
          path: "finance",
          element: <Finance />,
        },
        {
          path: "analytics",
          element: <Analytics />,
        },
        {
          path: "revenues-and-ratings",
          element: <Dashboard />,
        },
        {
          path: "advertisements-and-events",
          element: <Dashboard />,
        },
        {
          path: "advertisements-and-events/feature-property",
          element: <FeatureProperties />,
        },
        {
          path: "advertisements-and-events/feature-property/update/:id",
          element: <AddFeatureProperties />,
        },
        {
          path: "advertisements-and-events/feature-property/create",
          element: <AddFeatureProperties />,
        },
        {
          path: "advertisements-and-events/banners",
          element: <Banners />,
        },
        {
          path: "advertisements-and-events/banners/update/:id",
          element: <AddBanner />,
        },
        {
          path: "advertisements-and-events/banners/create",
          element: <AddBanner />,
        },
        {
          path: "events",
          element: <Dashboard />,
        },
        {
          path: "top-destinations",
          element: <TopDestinations />,
        },
        {
          path: "top-destinations/add",
          element: <AddTopDestination />,
        },
        {
          path: "top-destinations/update/:id",
          element: <AddTopDestination />,
        },
        {
          path: "effortless-travel",
          element: <EffortlessTravel />,
        },
        {
          path: "effortless-travel/create",
          element: <AddEffortlessTravel />,
        },
        {
          path: "effortless-travel/update/:id",
          element: <AddEffortlessTravel />,
        },
        {
          path: "blogs",
          element: <Blogs />,
        },
        {
          path: "blogs/create",
          element: <AddBlogs />,
        },
        {
          path: "blogs/update/:id",
          element: <AddBlogs />,
        },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <Navigate to={isLoggedIn ? "/dashboard" : "/login"} />,
    },
  ]);
  return router;
}

import React from "react";
import style from "./PageLayout.module.scss";
import Header from "../header";
import SideMenu from "../side-menu";
import Container from "../../container/index";

const PageLayout = ({ children }) => {
  return (
    <div className={style.page_layout}>
      <SideMenu />
      <div className={style.page_content}>
        {/* <Header /> */}
        <div className={style.content}>
          <Container>{children}</Container>
        </div>
      </div>
    </div>
  );
};

export default PageLayout;

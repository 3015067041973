import React, { useEffect, useState } from "react";
import styles from "./SideMenu.module.scss";
import logo from "../../../assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import arrowDown from "../../../assets/images/arrow-down.svg";
import logout from "../../../assets/images/logout-icon.svg";
import { clearUser } from "../../../features/userSlice";
import { useDispatch } from "react-redux";

const MENU_ITEMS = [
  {
    link: "/dashboard",
    name: "Home",
  },
  {
    link: "/bookings",
    name: "Bookings",
  },
  {
    link: "/property",
    name: "Property",
  },
  {
    link: "/user",
    name: "User",
  },
  {
    link: "/onboarding-requests",
    name: "Onboarding Requests",
  },
  {
    link: "/finance",
    name: "Finance",
  },
  {
    link: "/analytics",
    name: "Analytics",
  },
  {
    link: "/advertisements-and-events",
    name: "Advertisements & Events",
    children: [
      {
        link: "/advertisements-and-events/feature-property",
        name: "Feature Property",
        key: "a",
      },
      { link: "/advertisements-and-events/banners", name: "Banners", key: "b" },
    ],
  },
  {
    link: "/effortless-travel",
    name: "Effortless Travel",
  },
  {
    link: "/top-destinations",
    name: "Top Destinations",
  },
  {
    link: "/blogs",
    name: "Blogs",
  },
  // {
  //   link: "/blogs",
  //   name: "Logout",
  // },
];

function SideMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [activeMenu, setActiveMenu] = useState(null);
  const [showDropDown, setShowDropDown] = useState({
    index: "",
    isShown: false,
  });

  function fetchActiveMenu() {
    if (location.pathname === "/") {
      setActiveMenu(0);
    } else {
      let activeLink = MENU_ITEMS.findIndex(
        (item) =>
          item?.link?.split("/")[1] === location?.pathname?.split("/")[1]
      );
      if (MENU_ITEMS[activeLink]?.children) {
        MENU_ITEMS[activeLink]?.children?.forEach((child) => {
          if (child.link.split("/")[2] === location?.pathname?.split("/")[2]) {
            setActiveMenu(child.key);
          } else {
            return;
          }
        });
      } else {
        setActiveMenu(activeLink);
      }
    }
  }

  useEffect(() => {
    fetchActiveMenu();
  }, [location]);

  const handleClick = (type, index, link) => {
    const children = MENU_ITEMS[index]?.children;
    if (type === "parent") {
      if (children) {
        setShowDropDown((prevItem) => {
          if (prevItem.index === index) {
            return {
              ...prevItem,
              isShown: !prevItem.isShown,
            };
          } else {
            return {
              index: index,
              isShown: true,
            };
          }
        });
      } else {
        setShowDropDown(false);
        setActiveMenu(index);
        navigate(link);
      }
    } else if (type === "child") {
      setActiveMenu(index);
      navigate(link);
    }
  };

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div className={styles.side_menu}>
      <div className={styles.logo}>
        <img src={logo} alt="PASSIONBYTES" />
      </div>
      <div className={styles.menu_item}>
        <ul>
          {MENU_ITEMS.map(({ link, name, children }, index) => {
            return (
              <>
                <li
                  className={`${styles.main_link} ${
                    activeMenu === index && styles.activeMenu
                  }`}
                  key={index}
                  onClick={() => {
                    handleClick("parent", index, link);
                  }}
                >
                  <a className={styles.link_item}>
                    {name}
                    {children && (
                      <img
                        src={arrowDown}
                        className={`${
                          showDropDown.index === index &&
                          showDropDown.isShown &&
                          styles.arrow_flip
                        }`}
                      />
                    )}
                  </a>
                </li>
                {children &&
                  showDropDown.index === index &&
                  showDropDown.isShown && (
                    <ul className={styles.sub_menu}>
                      {children.map(({ link, name, key }, subIndex) => {
                        return (
                          <li
                            key={key}
                            className={`${styles.link} ${
                              activeMenu === key && styles.activeMenu
                            }`}
                            onClick={() => {
                              handleClick("child", key, link);
                            }}
                          >
                            <a className={styles.link_item}>{name}</a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
              </>
            );
          })}
        </ul>
      </div>
      <div className={styles.logout} onClick={handleLogout}>
        <img src={logout} alt="logout" />
        <p>Logout</p>
      </div>
    </div>
  );
}

export default SideMenu;

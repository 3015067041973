import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name:"user",
    initialState:{
        user:{},
        isLoggedIn:false,
    },
    reducers: {
        setUser: (state, action) => {
          state.user = action.payload;
          state.isLoggedIn = true;
        },
        clearUser: (state) => {
          state.user = {};
          state.isLoggedIn = false;
        },
      },
})
export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;

import React from "react";
import style from "./Loader.module.scss";
import loading from "../../assets/images/loading.svg";

const Loader = () => {
  return (
    <div className={style.loading}>
      <div className={style.loading_wrapper}>
        <img src={loading} />
      </div>
    </div>
  );
};

export default Loader;

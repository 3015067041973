import axios from "axios";
import { CONFIG } from "../config";

let token = "";
if (localStorage.getItem("token")) {
  token = localStorage.getItem("token");
}

const headers = {
  Accept: "application/json",
};

if (token) {
  headers.Authorization = `Token ${token}`;
}

export const http = axios.create({
  baseURL: CONFIG.API_ENDPOINT,
  timeout: 10000,
  headers,
});

import logo from "./logo.svg";
import "./App.css";
import Router from "./routes";
import { useEffect, useState } from "react";
import { http } from "./axios/http";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "./features/userSlice";
import "bootstrap/dist/css/bootstrap.min.css";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !isLoggedIn) {
      setLoading(true);
      http
        .get("auth/user/")
        .then((res) => {
          setLoading(false);
          const data = res?.data;
          dispatch(setUser(data));
          // navigate(`${location.pathname}${location.search}`);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.response);
          if (err.response && err.response.status === 401) {
            navigate("/login");
          } else {
            console.error(err);
          }
        });
    } else if (!token) {
      navigate("/");
    }
  }, []);
  return <Router />;
}

export default App;
